import React, { Component } from "react";
//import logo from './logo.svg';
//import {useEffect, useState} from 'react';
import {
  BrowserRouter,
  useLocation,
  Navigate,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import {
  ProvideAuth,
  useAuth,
  useGoogleProvider,
} from "./services/auth/use-auth";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";

/*
import {PayPalScriptProvider, PayPalButton, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import firebase from 'firebase/compat/app';
import {
    GoogleAuthProvider,
    signInWithRedirect,
    getRedirectResult,
    signInWithPopup,
    getAdditionalUserInfo
} from "firebase/auth";
import UserService from './services/user.service';
*/

//import Search from './pages/search/Search';
import Search2 from "./pages/search/Search2";
import ImageDetail from "./pages/imageDetails/ImageDetail";
import ImageStudio from "./pages/imageDetails/ImageStudio";

//import ImageDetailOld from './pages/imageDetails/ImageDetailOld';
import Profile from "./pages/profile/profile";
//import Pricing from './pages/pricing/pricing';
//import Pricing2 from './pages/pricing/pricing2';
import Credits from "./pages/credits/credits";
import ProfileEdit from "./pages/profile/edit/edit";
//import Packs from './pages/packs/Packs';
//import Pack from './pages/packs/Pack';
import ScrollToTop from "./components/scroll-to-top/scrollTopTop";
import LoginPage from "./components/Auth/LoginPage";
import SignUpPage from "./components/Auth/SignUpPage";
import FreeSignupPage from "./components/Auth/FreeSignUpPage";
//import Payment from './components/Payment/Payment';
import Categories from "./pages/search/Categories";
import ResetPage from "./components/Auth/ResetPage";
import PageNotFound from "./pages/pageNotFound/PageNotFound";

import CreditCardForm from "./pages/payment/CreditCardForm";
import SignupPayment from "./pages/signup-payment/SignupPayment";
import SignupComplete from "./pages/signup-complete/SignupComplete";

function RequireAuth(o) {
  const auth = useAuth();

  const location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return o.children;
}

function SignOut() {
  const auth = useAuth();
  const navigate = useNavigate();
  auth.signout();
  navigate("/");
  return null;
}

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <ProvideAuth>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/search" element={<Search2 />}></Route>
            <Route path="/search2" element={<Search2 />}></Route>
            <Route path="/image" element={<ImageDetail />}></Route>
            <Route
              path="/studio"
              element={
                <RequireAuth>
                  <ImageStudio />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/edit"
              element={
                <RequireAuth>
                  <ProfileEdit />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset" element={<ResetPage />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/free-signup" element={<SignUpPage />} />
            <Route path="/signup-complete" element={<SignupComplete />}></Route>

            <Route path="/credits" element={<Credits />}></Route>
            {/* REMOVED PAYMENT PAGES
                        <Route path="/paymentcc" element={<CreditCardForm/>}></Route>                        
                        <Route path="/signup-payment" element={<SignupPayment/>}></Route>
                        <Route path="/pricing" element={<Pricing2/>}></Route> 
                        <Route path="/payment" element={<Payment/>}></Route> 
                        */}

            {/* REMOVED CONTENT PACK PAGES
                            <Route path="/packs" element={<Packs/>}></Route>
                            <Route path="/pack" element={<Pack/>}></Route>
                        */}
            <Route path="/categories" element={<Categories />}></Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ProvideAuth>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
