import { apiURI } from "../shared/api.settings";
import colorsList from "./list.data";


class ListService {
 
    static GetRelatedFromKeyword = async (keyword) => {
        return fetch(`${apiURI}/search/related/${keyword}`)
            .then((response) => response.json());
    }
    static GetTrendingSearches = async () => {
        return fetch(`${apiURI}/search/trending/1`)
            .then((response) => response.json());
    }
    static GetTrendingSearches2 = async () => {
        return fetch(`${apiURI}/search/trending2/1`)
            .then((response) => response.json());
    }
    
    static GetTopics = async () => {
        return fetch(`${apiURI}/topic`)
            .then((response) => response.json());
    }

       /*
    static GetCategories = async () => {
        return fetch(`${apiURI}/category`)
            .then((response) => response.json());
    }
    */
    static GetCategories = async () => {
        let categories = [
            "Animals",
            "Anime",
            "Architecture",
            "Art",
            "Beauty",
            "Business",
            "Characters",
            //"Centerpieces",
            "Cinema",
            "Comics",
            "Culinary",
            "Education",
            "Environments",
            "Fantasy",
            "Fashion",
            "Floral Arrangements",
            "Gaming",
            //"Health",
            "Horror",
            "Holidays",
            "Interior Design",
            "Illustrations",
            "Logos",
            "Materials",
            "Nature",
            "Portraits",
            "Robots",
            "Science",
            "Science Fiction",
            "Sports",
            "Technology",
            "Textures",
            "Toys",
            "Transportation",
            "Travel",
            "Urban",
            "Work"
        ];

        return categories.sort();
    }


    static GetCategoryTopicMappings() {
        let mappings = [
            {category: 'Architecture', topics: ['Buildings', 'Entertainment', 'Luxury', 'Commercial', 'Residential']},
            {category: 'Animals', topics: ['Birds', 'Dogs', 'Cats','Reptiles', 'Pets', 'Mammals', 'Fish', 'Insects']},
            {category: 'Anime', topics: ['Manga', 'Cartoons', 'Urban','Chibi','Harajuku','Cyberpunk']},
            {category: 'Art', topics: ['Painting', 'Sketch', 'Sculpture','Abstract','Impressionism','Expressionism','Surrealism','Graffiti', 'Writing']},
            {category: 'Beauty', topics: ['Influencers', 'Fashion', 'Makeup', 'Skincare', 'Nails', 'Hair']},
            {category: 'Business', topics: ['Finance', 'Marketing', 'Technology','Industry','Events','Conferences','Meetings','Offices','Workspaces','Executives','Laborers','Professions','Jobs','Careers','Entrepreneurs','Startups','Innovation','Research','Science']},
            {category: 'Characters', topics: ['Concept Art', 'Cyberpunk','Illustration', 'Cartoons','Animations','Comics','Science Fiction','Fantasy'] },
            {category: 'Cinema', topics: ['Love', 'Urban', 'Science Fiction', 'Fantasy','Drama'] },
            {category: 'Centerpieces', topics: ['Crystals','Luxury','Lighting','Vases','Decorations', 'Appliances', 'Interior Design', 'Blown Glass'] },           
            {category: 'Comics', topics: ['Superheroes','Villains','Urban'] },
            {category: 'Culinary', topics: ['Entrees', 'Drinks', 'Meats','Vegetables','Desserts','Funny','Pizza', 'Sides', 'Soups','Seafood']},
            {category: 'Education', topics: ['Education', 'Learning', 'Schools', 'Students','Science']},
            {category: 'Environments', topics: ['Urban', 'Concepts', 'Exoplanets', 'Caverns','Lakes','Geological Design']},
            {category: 'Fantasy', topics: ['Cyberpunk','Surrealism','Bards', 'Dreamscapes', 'Character Design', 'Elves', 'Monks','Fey Creatures','Dwarves','Warlocks','Rangers','Witches','Undead','Orcs','Trolls','Sorcerers','Magicians', 'Fighters','Dragons','Zombies','Crystals','Magic Items', 'Scrolls', 'Monsters' ]},
            {category: 'Fashion', topics:['Accessories','Fashion','Purses','Sneakers','Pants','Dresses','Hats','Jewelry','Watches','Jackets']},
            {category: 'Floral Arrangements', topics:['Flowers','Plants','Vases','Roses','Holidays','Valentines']},          
            {category: 'Gaming', topics: ['Assets','Survival','Horror','Racing','Sports','Fighting','Shooter','Adventure','Retro','Arcade' ]},
            {category: 'Health', topics: ['Health', 'Fitness', 'Wellness', 'Exercise']},
            {category: 'Holidays', topics: ['Holidays', 'Celebrations', 'Winter','Spring','Easter','Halloween','Valentines','Thanksgiving']},
            {category: 'Horror', topics: ['[Dolls','Zombies', 'Ghosts', 'Fear', 'Aliens','Monsters','Clowns']},
            {category: 'Interior Design', topics: ['Vases','Centerpieces','Decorations','Bathrooms', 'Living Rooms', 'Kitchens', 'Dining Rooms','Basements','Backyards','Pools','Bedrooms','Closets','Garages','Entryways']},
            {category: 'Illustrations', topics: ['Logos','Posters','Poster Art'] },            
            {category: 'Materials', topics: ['Centerpieces','Materials', 'Texture', 'Patterns', 'Backgrounds']},
            {category: 'Nature', topics: ['Nature', 'Animals','Landscapes', 'Mountains', 'Oceans','Plants','Flowers','Earth','Islands','Environments','Forests','Nature Reserves']},
            {category: 'Portraits', topics: ['Persons', 'White', 'Students','Americans', 'Hispanics','Asians','Native Americans', 'Africans','African Americans','Gamers','Hackers','Models','Jocks','Athletes','Europeans','Cubans','Puerto Ricans','Dominicans','Mexicans','Venezuelans','Columbians','British','Irish','Cambodians','Vietnamese','Koreans']},
            {category: 'Places', topics: ['Places', 'Cities', 'Countries', 'Locations']},
            {category: 'Science', topics: ['Science', 'Research', 'Innovation', 'Biology', 'Technology','Physics','Chemistry','Mathematics','Cosmology']},
            {category: 'Science Fiction', topics: ['Exoplanets', 'Artificial Intelligence','Space','Post-Apocalyptic','Robots','Futuristic','Cyberpunk','Exploration','Monsters','Aliens']},
            {category: 'Sports', topics: ['Soccer','Football','Baseball','Basketball','Running','Hiking','Cycling','Tennis','Golf','Hockey','Racing','Boxing','MMA','Wrestling','Track & Field','Swimming','Equipment','Gymnastics']},
            {category: 'Technology', topics: ['Technology', 'Computers', 'Software', 'Hardware']},
            {category: 'Transportation', topics: ['Cars', 'Automobiles', 'Vehicles', 'Trucks', 'Motorcycles', 'Bicycles']},
            {category: 'Toys', topics: ['Trains', 'Dolls', 'Plushies', 'Futuristic', 'Robots']},
            {category: 'Travel', topics: ['Travel', 'Vacations', 'Trips', 'Journeys','Beaches','Resorts','Destinations']},
            {category: 'Urban', topics: ['Graffiti', 'Cityscapes', 'Hip-hop','Breakdancers','Comics','Illustrations']},                
            {category: 'Work', topics: ['Work', 'Jobs', 'Careers', 'Professions','Laborers','Executives']},        
        ];

        return mappings;
    }

    static GetTopicsForCategory = async (categoryId) => {
       
        let mappings = ListService.GetCategoryTopicMappings();

        if (categoryId === 'All') {
            let allTopics = mappings.map(m => m.topics).flat();

            // remove dupes
            for (let i = 0; i < allTopics.length; i++) {
                for (let j = i + 1; j < allTopics.length; j++) {
                    if (allTopics[i] === allTopics[j]) {
                        allTopics.splice(j--, 1);
                    }
                }
            }
            
            return allTopics.sort();
        }

        let found = mappings.filter(m => m.category === categoryId)
        if (found.length === 0) {
            return [];
        }
        if (found === 'null' || found === null || found === undefined) {
            return [];
        }

        let filtered =  found[0].topics;

        if (filtered.length === 0) {
            return [];
        }

        // sort and return

        return filtered.sort();        
    }

    static GetAllTopics = async () => {
        let mappings = ListService.GetCategoryTopicMappings();

        let allTopics = mappings.map(m => m.topics).flat();

        // remove dupes
        for (let i = 0; i < allTopics.length; i++) {
            for (let j = i + 1; j < allTopics.length; j++) {
                if (allTopics[i] === allTopics[j]) {
                    allTopics.splice(j--, 1);
                }
            }
        }

        return allTopics.sort();
    }



    /*
    static GetColors = async () => {
        return fetch(`${apiURI}/color`)
            .then((response) => response.json());
    }
*/

    static GetColors() {
        var colors = colorsList;

        // setup a 3D vector from hex value
        /*
        colors.forEach((color) => {
            color.vector = new Math.Vector3();
            color.vector.setHex(color.hex);
        });
        */

        // sort colors by hue
        colors.sort((a, b) => {
            return a.hex - b.hex;
        });

        return colors;

    }

    static GetMaterials = async () => {
        return fetch(`${apiURI}/materials`)
            .then((response) => response.json());
    }

    static GetTag = async () => {
        return fetch(`${apiURI}/tag`)
            .then((response) => response.json());
    }

    static GetLocation = async () => {
        return fetch(`${apiURI}/location`)
            .then((response) => response.json());
    }

    static GetResolution = async () => {
        return fetch(`${apiURI}/resolution`)
            .then((response) => response.json());
    }

      static SubscribeNewsletter = async (email, mode) => {
        
        var bodyContent = {
            email: email,
            mode: mode
        };

        // console.log("SubscribeNewsletter: bodyContent = " + JSON.stringify(bodyContent));
      
        return fetch(`${apiURI}/collections/newsletter?email=${email}&mode=${mode}`, {
            method: "POST",
            mode: "no-cors", 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyContent),
            })
            .then((response) => response.json());            
    }
}

export default ListService;